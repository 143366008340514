import { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbar, gridClasses } from '@mui/x-data-grid';

interface SelectorItem {
  id: string;
  name: string;
  description?: string;
}

interface DependentTableItem {
  [key: string]: string | number | boolean | null | undefined;
}

interface DependentTableProps<T> {
  idField: keyof T & string;
  selectorItems: SelectorItem[];
  selectorLabel: string;
  selectedId: string;
  isLoadingSelector: boolean;
  selectorError?: Error;
  tableData: T[];
  tableColumns: GridColDef[];
  isLoadingTable: boolean;
  tableError?: Error;
  onSelectorChange: (selectedId: string) => void;
  defaultPageSize?: number;
  pageSizeOptions?: number[];
  title?: string;
  icon?: React.ReactNode;
}

const DependentTable = <T extends DependentTableItem>({
  idField,
  selectorItems,
  selectorLabel,
  selectedId,
  isLoadingSelector,
  selectorError,
  tableData,
  tableColumns,
  isLoadingTable,
  tableError,
  onSelectorChange,
  defaultPageSize = 20,
  pageSizeOptions = [10, 20, 30, 40, 50],
  title = 'Select an option',
  icon,
}: DependentTableProps<T>) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: defaultPageSize,
  });

  const handleSelectorChange = (event: SelectChangeEvent<string>) => {
    const newId = event.target.value;
    onSelectorChange(newId);
    setPaginationModel(prev => ({ ...prev, page: 0 }));
  };

  if (selectorError) {
    return <div>Error loading selector: {selectorError.message}</div>;
  }

  if (tableError) {
    return <div>Error loading table data: {tableError.message}</div>;
  }

  return (
    <Card sx={{ mt: 4 }}>
      <CardHeader avatar={icon} title={title} />
      <Divider />
      <CardContent>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
          <FormControl>
            <InputLabel id="dependent-selector-label">{selectorLabel}</InputLabel>
            <Select
              labelId="dependent-selector-label"
              value={selectedId}
              label={selectorLabel}
              onChange={handleSelectorChange}
              disabled={isLoadingSelector}
              sx={{ minWidth: 200 }}>
              {selectorItems.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!selectedId && <Typography color="text.secondary">Select an option</Typography>}

          <Box sx={{ width: '100%', minHeight: 800 }}>
            <DataGrid
              autoHeight
              sx={{
                [`& .${gridClasses.cell}`]: {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
              columns={tableColumns}
              rows={tableData}
              loading={isLoadingTable}
              pagination
              paginationMode="client"
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={pageSizeOptions}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              getRowId={(row: T) => row[idField] as string | number}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export { DependentTable };
export type { DependentTableItem };
