import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useRoleUsers = (role_id: string) => {
  const url = role_id ? encodeURI(`${REACT_APP_API_BASE_URL}/roles/${role_id}/users`) : null;
  const { data, error, isLoading, mutate } = useSWR(url);

  return {
    users: data || [],
    error,
    isLoading,
    mutate,
  };
};

export default useRoleUsers;
