import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Group, Shield } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';

import useRoles from 'hooks/useRoles';
import useRoleUsers from 'hooks/useRoleUsers';
import IRole from 'interfaces/Role';
import RoleTableRow from 'components/roles/RoleTableRow';
import { DependentTable, DependentTableItem } from '../../components/DependentTable';

interface RoleUser extends DependentTableItem {
  table_item_id: string;
  name: string;
  email: string;
  picture?: string;
}

const RolesPage = () => {
  const { roles, error: rolesError, isLoading: isLoadingRoles } = useRoles();
  const [selectedRoleId, setSelectedRoleId] = useState<string>('');
  const { users, error: usersError, isLoading: isLoadingUsers } = useRoleUsers(selectedRoleId);

  const userColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    if (roles?.length > 0 && !selectedRoleId) {
      const adminRole = roles.find((role: IRole) => role.name === 'User Management Admin');
      setSelectedRoleId(adminRole?.id || roles[0].id); // Fallback
    }
  }, [roles, selectedRoleId]);

  return (
    <>
      <Card>
        <CardHeader avatar={<Shield />} title="Roles & Permissions" />
        <Divider />
        <CardContent>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Role</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles?.map((role: IRole) => <RoleTableRow key={role.id} role={role} />)}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <DependentTable<RoleUser>
        title="Role Members"
        icon={<Group />}
        selectorLabel="Select Role"
        idField="user_id"
        selectorItems={roles || []}
        selectedId={selectedRoleId}
        isLoadingSelector={isLoadingRoles}
        selectorError={rolesError}
        tableData={users || []}
        tableColumns={userColumns}
        isLoadingTable={isLoadingUsers}
        tableError={usersError}
        onSelectorChange={setSelectedRoleId}
      />
    </>
  );
};

export default RolesPage;
